'use strict';

// eslint-disable-next-line require-jsdoc
function ajaxCall(url, $form, container) {
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            container.spinner().stop();
            if (!data.success) {
                $('.languageModal-error').removeClass('d-none');
            } else {
                location.reload(); //eslint-disable-line
            }
        },
        error: function () {
            container.spinner().stop();
            $('.languageModal-error').removeClass('d-none');
        }
    });
}

$(function () {
    var scrollBarWidths = 40;

    /**
     * Get value of .list li width items
     * @returns {integer} width in pixels
     */
    var getListWidth = function () {
        var itemsWidth = 0;
        $('.list li').each(function () {
            var itemWidth = $(this).outerWidth();
            itemsWidth += itemWidth;
        });
        return itemsWidth;
    };

    /**
     * Get visible width
     * @returns {number} visible width in pixels
     */
    var getVisibleWidth = function () {
        return $('.wrapper').outerWidth();
    };

    /**
     * Get width of the hidden part of the list on the left
     * @returns {number}  width in pixels
     */
    var getHiddenLeftWidth = function () {
        return -($('.list').position().left);
    };

    /**
     * Get width of the hidden part of the list on the right
     * @returns {number} width in pixels
     */
    var getHiddenRightWidth = function () {
        var listWidth = getListWidth();
        var visibleWidth = getVisibleWidth();
        var hiddenLeft = getHiddenLeftWidth();
        return (listWidth - hiddenLeft - visibleWidth + scrollBarWidths);
    };

    /**
     * Check if horizontal scroll is needed or not and enables/disables necessary parts
     */
    var reAdjust = function () {
        if (getVisibleWidth() < getListWidth() || $('.wrapper .nav').height() > 40) {
            $('.scroller-right').removeClass('d-none');
            $('.scroller-left').removeClass('d-none');
            $('.wrapper .nav').removeClass('list-center');
            $('.wrapper .nav').addClass('list');
        } else {
            $('.scroller-right').addClass('d-none');
            $('.scroller-left').addClass('d-none');
            $('.wrapper .nav').removeClass('list');
            $('.wrapper .nav').addClass('list-center');
        }
        $('.scroller').height($('.wrapper').outerHeight());
        var totalHeight = $('.wrapper').outerHeight() - parseInt($('.wrapper').css('padding-bottom'), 10);
        $('.scroller').css({ height: (totalHeight + 'px') });
    };

    // first check on load
    reAdjust();

    $(window).on('resize', function () {
        reAdjust();
    });

    var getActiveWidth = function () {
        return ($('.ca_subcat_menu_item.active').position().left);
    };

    var getFirstElementWidth = function () {
        return -($('.ca_subcat_menu_item').first().outerWidth());
    };

    $(function () {
        $('.ca_subcat_menu_item').each(function() {
            $('.active').focus();
            var activeWidth = getActiveWidth();
            var firstElementWidth = parseInt(getFirstElementWidth());
            if ($(this).is('.active')) {
                var tab_index = $(this).prop('tabindex');
                if (tab_index == 6 || tab_index == 7 ) {
                    $('.list').css('left', (firstElementWidth * 2));
                }

                if (tab_index == 8) {
                    $('.list').css('left', (firstElementWidth * 4));
                }
            }
            // for hiding funnel
            if ($('#notAvailable').length === 1) {
                $(".filters").addClass("d-none");
                return;
            }
        });
    });
    $('.scroller-right').on('click', function () {
        if (!$('.list').data('animating')) {
            $('.list').data('animating', true);
            var scrollinRight = getVisibleWidth();
            if (getVisibleWidth() > getHiddenRightWidth()) scrollinRight = getHiddenRightWidth();
            $('.list').animate({ left: '+=' + -(scrollinRight) + 'px' }, 'slow', function () {
                $('.list').data('animating', false);
            });
        }
    });

    $('.scroller-left').on('click', function () {
        if (!$('.list').data('animating')) {
            $('.list').data('animating', true);
            var scrollingLeft = getVisibleWidth();
            if (getVisibleWidth() > getHiddenLeftWidth()) scrollingLeft = getHiddenLeftWidth();
            $('.list').animate({ left: '-=' + -(scrollingLeft) + 'px' }, 'slow', function () {
                $('.list').data('animating', false);
            });
        }
    });

    $('.submit-language').on('click', function (e) {
        e.preventDefault();
        var radioValue = $("input[name='radio-group']:checked").val();
        $('input#language').val(radioValue);
        var thisObject = $(this);
        var modal = $('.modal-content');
        var $form = $('form.' + thisObject.attr('data-form'));
        var url = $('form.edit-profile-form').attr('action');
        modal.spinner().start();
        $('form.edit-profile-form').trigger('profile:edit', e);
        ajaxCall(url, $form, modal);
        return false;
    });

    $('#dropdownMenuLink').on('click', function () {
        $('.ca_account_lang_select_dropdown').toggleClass('show');
        $('.dropdown-menu').toggleClass('show');
    });

    $('.dropdown-menu li').on('click', function (e) {
        $('#dropdownMenuLink').html($(this).text());
        $('#dropdownMenuLink').attr('data-value', $(this).attr('data-value'));
        $('.dropdown-menu').removeClass('show');
        $('.ca_account_lang_select_dropdown').removeClass('show');
        var radioValue = $(this).attr('data-value');
        $('input#language').val(radioValue);
        var thisObject = $(this);
        var container = $('.language-select');
        var $form = $('form.' + thisObject.attr('data-form'));
        e.preventDefault();
        var url = $('form.customer-profile-form').attr('action');
        container.spinner().start();
        $('form.customer-profile-form').trigger('profile:customer', e);
        ajaxCall(url, $form, container);
        return false;
    });
});
