'use strict';

var loginHelpers = require('./login/customerLogin');
var siteModalHelpers = require('./components/siteModal');

/**
 * SHADOW ON SCROLL
 * header menu
 */
function initNavbarShadow() {
    var $navbar = $('.navbar');
    $(window).on('scroll', function () {
        if ($(window).scrollTop() <= 40) {
            $navbar.css('box-shadow', 'none');
        } else {
            $navbar.css('box-shadow', '0 3px 8px 0px rgba(111, 127, 134, .3)');
        }
    });
}
/**
 * HEADER MENU SEARCH
 */
function initHeaderSearch() {
    if ($('.ca_search').length) {
        var isCustomerUnlimited = ($('#customerUnlimited').val() === 'true');
        $('.ca_search').on('click', function () {
            if ($('.ca_header_search').hasClass('active')) {
                $('.ca_header_search').removeClass('active');
                $(this).children('i').removeClass('icon-cross');
                $(this).children('i').addClass('icon-search');
            } else {
                if (isCustomerUnlimited && window.location.href.indexOf('ctype=unlimited') === -1) {
                    window.history.replaceState(null, null, '?ctype=unlimited');
                }
                $('.ca_header_search').addClass('active');
                $('.form-control.search-field').focus();
                $(this).children('i').addClass('icon-cross');
                $(this).children('i').removeClass('icon-search');
            }
        });
    }
}

/**
 * HEADER MENU options menus for customer
 */
function initHeaderCustomer() {
    if ($('.ca_user_name.user').length) {
        $('.ca_user_name.user').on('mouseenter', function () {
            $('.terminalist-opts').hide();
            $('.user-opts').toggle();
        });
    }
}

/**
 * HEADER MENU options menus for terminalist
 */
function initHeaderTerminalist() {
    if ($('.ca_header_item.terminalist').length) {
        $('.ca_header_item.terminalist').on('click', function () {
            $('.user-opts').hide();
            $('.terminalist-opts').toggle();
        });
    }
}

/**
 * Set an expiring localStorage
 * @param {*} key key id for the localStorage value
 * @param {*} value value to be saved at localStorage
 * @param {*} ttl time that the localStorage variable will be alive
 */
function setExpiryStorage(key, value, ttl) {
    var now = new Date();

    var item = {
        value: value,
        expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Get the value for a expiring localStorage
 * @param {*} key the key of the variable to be retrieved
 * @returns {*} returns the value of the localStorage
 */
function getExpiryStorage(key) {
    var itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    var item = JSON.parse(itemStr);
    var now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}

/**
 * Save current terminalist info in localStorage
 */
function saveCurrentTerminalist() {
    var lastTerminalist = getExpiryStorage('terminalistID');
    var lastCenter = getExpiryStorage('terminalistCenter');
    if (lastTerminalist && lastCenter && lastTerminalist !== 'undefined' && lastCenter !== 'undefined') {
        var currentTerminalist = $('#terminalistID').html().toString();
        var currentCenter = $('#terminalistCenter').html().toString();
        if ((lastTerminalist !== currentTerminalist) && (lastCenter !== currentCenter)) {
            // Avoid updating the saved data with the default values
            if (currentTerminalist !== 'U01A0635' && currentCenter !== '09712') {
                // Set expiry time for localStorage at 12 hours
                setExpiryStorage('terminalistID', currentTerminalist, 43200000);
                setExpiryStorage('terminalistCenter', currentCenter, 43200000);
            }
        }
    }
}

/**
 * Save current time session in localStorage
 */
 function saveTimeSessionNow() {
    var timeSession = getExpiryStorage('timeSession');
    var timeNow = new Date();
    if (timeSession !== timeNow) {
        // Set expiry time for localStorage at 15 minutes
        setExpiryStorage('timeSession', timeNow, 900000);
    }
}

/**
 * TRIGGER HEADER REMOTE INCLUDES DINAMICALLY
 */
function triggerRemoteIncludes() {
    var ri = $('div[data-remoteinclude]');
    if (ri.length) {
        ri.each(function () {
            var wai = $(this).find('wainclude');
            if (wai && wai.length) {
                var riURL = $(this).data('remoteinclude');
                $.ajax({
                    url: riURL,
                    method: 'get',
                    context: this,
                    success: function (data) {
                        if (data.renderedTemplate) {
                            $(this).html(data.renderedTemplate);
                            // Init required events when html template is available
                            if ($(this).hasClass('siteChangeInclude')) {
                                siteModalHelpers.initSiteModal();
                            }
                            if ($(this).hasClass('customerShowInclude')) {
                                loginHelpers.login();
                            }
                            if ($(this).hasClass('headerInclude')) {
                                initHeaderCustomer();
                            }
                        }
                    }
                });
            }
        });
    }
}

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i += 1) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

/**
 * CONTROL VISIBILITY OF PILOT CATEGORIES
 */
function controlCategoryPilotVisibility() {
    var pilotCategory = $('#ca_category_menu').find('[data-is-pilot="true"]');
    var isPilotCategory = pilotCategory.data('is-pilot');
    if (pilotCategory.length) {
        pilotCategory.on('click', function (e) {
            e.preventDefault();
            var dwStoreCookie = getCookie('dw_store');
            var pilotOffices = $(this).attr('data-pilot-offices').split(',');
            if (dwStoreCookie !== '' && pilotOffices.indexOf(dwStoreCookie) <= -1) {
                // No category visibility in current office - open modal
                var visibilityModal = $('#categoryVisibilityModal');
                var categoryName = $(this).text().trim();
                visibilityModal.find('.modal-title').text(categoryName);
                visibilityModal.modal('show');
            } else {
                window.location.href = $(this).attr('href');
            }
        });
    }
    return isPilotCategory;
}

/**
 * CONTROL VISIBILITY OF PILOT SUBCATEGORIES
 */
function controlSubCategoryPilotVisibility() {
    var pilotSubCategory = $('.submenu_container').find('[data-is-pilot="true"]');
    var isPilotSubCategory = pilotSubCategory.data('is-pilot');
    if (pilotSubCategory.length) {
        pilotSubCategory.on('click', function (e) {
            e.preventDefault();
            var dwStoreCookie = getCookie('dw_store');
            var pilotOffices = $(this).attr('data-pilot-offices').split(',');
            if (dwStoreCookie !== '' && pilotOffices.indexOf(dwStoreCookie) <= -1) {
                // No category visibility in current office - open modal
                var visibilityModal = $('#categoryVisibilityModal');
                var categoryName = $(this).text().trim();
                visibilityModal.find('.modal-title').text(categoryName);
                visibilityModal.modal('show');
            } else {
                window.location.href = $(this).attr('href');
            }
        });
    }
    return isPilotSubCategory;
}

/**
 * Manage session expired - show warning modal
 */
function manageSessionExpired() {
    $(document).ajaxSend(function (e, xhr) {
        var url = $('#sessionExpiredModal').attr('data-check-session-url');
        $.ajax({
            url: url,
            type: 'GET',
            global: false,
            success: function (data) {
                if (!data.session) {
                    xhr.abort();
                    // Close any opened modal
                    $('.modal').modal('hide');
                    // Show modal session expired
                    var sessionExpiredModal = $('#sessionExpiredModal');
                    var authUrl = $('.page').attr('data-auth-url');
                    sessionExpiredModal.find('.ca_btn_primary').attr('data-auth-url', authUrl);
                    sessionExpiredModal.modal('show');
                }
            },
            error: function () {
                xhr.abort();
            }
        });
    });
}

/**
 * Triggers TF authentication redirect
 */
function triggerAuthRedirect() {
    $('#sessionExpiredModal').on('hidden.bs.modal', function () {
        // location.reload();
        var authUrl = $(this).find('.ca_btn_primary').attr('data-auth-url');
        window.location.href = authUrl;
    });
    $('#unsubscribeModal .close').on('click', function () {
        // location.reload();
        if ($('#unsubscribeModal .modal-header .modal-title i').hasClass('loicon-check-circle')) {
            var authUrl = $('#unsubscribeModal').find('#unsubscribeHome').attr('href');
            window.location.href = authUrl;
        } else if ($('#unsubscribeModal .modal-header h1').hasClass('red')) {
            window.location.reload();
        }
    });
}

/**
 * Checks if the current session terminalist is equal to the one saved, if not, 
 * it means session is lost and terminalist needs to be updates
 */
function updateTerminalist() {
    var currentTerminalistCenter = $('#terminalistCenter').html();
    var currentTerminalistID = $('#terminalistID').html();
    var params = {
        terminalistID: getExpiryStorage('terminalistID'),
        terminalistCenter: getExpiryStorage('terminalistCenter')
    };

    if ((params.terminalistCenter !== currentTerminalistCenter) || (params.terminalistID !== currentTerminalistID)) {
        var url = $('.page').data('uptade-terminalist-url');
        $.ajax({
            url: url,
            type: 'GET',
            global: false,
            success: function (data) {
                if (data.success) {
                    // Refresh the templete rendered values with the correct ones
                    $('#terminalistCenter').html(params.terminalistCenter);
                    $('#terminalistID').html(params.terminalistID);
                }
            },
            error: function () {}
        });
    }
}


/**
 * Reload PLP including param for customer type (unlimited/standard)
 */
function filterPLPByCustomer() {
    // TODO: POP PLP Infinity/Unlimited: Do this for all elements required that load PLP (Search-Show)
    $('.ca_cat_link, .ca_subcat_menu_item, .ca-pagination, .ca_search').on('click', function (e) {
    // $('input[name=customerUnlimited]').on('change', function () {
        e.preventDefault();
        var redirectUrl = $(this).attr('href');
        var isCustomerUnlimited = ($('#customerUnlimited').val() === 'true');
        // var ctype = isCustomerUnlimited ? 'unlimited' : 'standard';
        if (isCustomerUnlimited && redirectUrl !== undefined) {
            var qsConnector = redirectUrl.indexOf('?') >= 0 ? '&' : '?';
            window.location.href = redirectUrl + qsConnector + 'ctype=unlimited';
        } else if (!$(e.target).hasClass('btn-pagination') && redirectUrl !== undefined) {
            window.location.href = redirectUrl;
        }
        // Moved logic to search displayer listener to allow copy paste search
        /* $('.search-field').on('keypress', function () {
            if (isCustomerUnlimited && window.location.href.indexOf('ctype=unlimited') === -1) {
                window.history.replaceState(null, null, '?ctype=unlimited');
            }
        }); */
    });
}

/**
 * Removes the wivai infinity subcategory for non-unlimited users
 */
function hideInfinity() {
    var isCustomerUnlimited = ($('#customerUnlimited').val() === 'true');
    if (!isCustomerUnlimited) {
        $('.ca_wivai-inifinity_plp').css({
            display: 'none',
            'pointer-events': 'none'
        });
    }
}

/**
 * Redirects the user to the "All" category if they landed on wivai infinity and they are not unlimited users
 */
function redirectToAll() {
    var isCustomerUnlimited = ($('#customerUnlimited').val() === 'true');
    var rediretUrl = window.location.href;
    if (!isCustomerUnlimited && rediretUrl.indexOf('wivai-infinity') !== -1) {
        window.location.href = rediretUrl.replace('-wivai-infinity', '');
    }
}

$(function () {
    initHeaderCustomer();
    initHeaderTerminalist();
    initHeaderSearch();
    initNavbarShadow();
    controlCategoryPilotVisibility();
    controlSubCategoryPilotVisibility();
    triggerRemoteIncludes();
    manageSessionExpired();
    triggerAuthRedirect();
    if (!controlCategoryPilotVisibility() && !controlSubCategoryPilotVisibility()) {
        filterPLPByCustomer();
    }
    hideInfinity();
    redirectToAll();
    saveCurrentTerminalist();
    saveTimeSessionNow();
    updateTerminalist();

    // close elements on click outside
    $(document).on('click', function (e) {
        // header option menus
        if (!$('.nav-item').is(e.target) && !$('.nav-item').has(e.target).length) {
            $('.ca_header_item-opts').hide();
        }

        // sort options
        if ((!$('.ca_plp_sort-btn').is(e.target) && !$('.ca_plp_sort-btn').has(e.target).length)) {
            $('.ca_plp_sort-opts').hide();
        }
    });

    $(document).on('click', '.ca-modal-site .modal-header .close', function () {
        // si dan click alboton cerrar >>
        $('.btn-modal-customer-type').removeClass('active');
        let defaultCustomer = $('.default-customer-type').val();
        $('.btn-modal-customer-type[value=' + defaultCustomer + ']').addClass('active');

        $('.btn-modal-base-type').removeClass('active');
        let defaultBase = $('.default-base-type').val();
        $('.btn-modal-base-type[value=' + defaultBase + ']').addClass('active');
    });
    $(document).on('click', '#unsubscribeConfirm', function () {
        var url = $(this).attr('data-delete-user');
        $.ajax({
            url: url,
            type: 'POST',
            global: false,
            success: function (data) {
                if (data.success) {
                    $('#unsubscribeHome').removeClass('border-grey')
                    $('#unsubscribeConfirm').addClass('d-none');
                    // eslint-disable-next-line max-len
                    $('#unsubscribeModal .modal-header .modal-title i').removeClass('icon-warning-circle').addClass('loicon-check-circle');
                } else {
                    $('#unsubscribeModal .modal-header h1').addClass('red');
                    $('#unsubscribeConfirm').html(data.errorButtonRetry);
                }
                $('#unsubscribeModal .modal-header h4').html(data.messageTitle);
                $('#unsubscribeModal .ca-modal-text').html(data.message);
                $('#unsubscribeCancel').addClass('d-none');
                $('#unsubscribeHome').removeClass('d-none').html(data.errorButtonCatalog);
                $('#unsubscribeHome').attr('href', data.urlRedirect);
            },
            error: function () {
            }
        });
    });
});
