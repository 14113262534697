/* eslint-disable newline-per-chained-call */

'use strict';

/**
 * Given a text, replaces the number in it
 * @param {string} text text to search in
 * @param {number} num number to replace
 * @returns {text} updated text
 */
function replaceNumberInString(text, num) {
    var textParts = text.trim().split(' ');
    var newText = '';
    textParts.forEach(function (part) {
        if (Number.isNaN(Number.parseFloat(part))) {
            newText += part + ' ';
        } else {
            newText += num + ' ';
        }
    });
    return newText;
}

// TODO: Format currency
/**
 * updated simulator summary with selected quota
 * @param {DOMElement} quotaSelected - product set complement selected
 */
function updateSimulationSummary(quotaSelected) {
    if (quotaSelected.find('.totalAmountElement').text() === '') {
        $('.totalPrice').html(quotaSelected.find('input[name=quote]').attr('data-totalpriceformatted'));
    } else {
        // Incluye formatter for correct price displaying with set prices
        var totalPrice = parseFloat(quotaSelected.find('.totalAmountElement').text().replace('.', '').replace(',', '.'));
        if (totalPrice) {
            totalPrice = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalPrice);
        }
        $('.totalPrice').html(totalPrice);
    }
    $('.nQuote').html(quotaSelected.find('.quota-price-month').data('origin-quote'));
    $('.quote').html(quotaSelected.find('.quoteElement').text());
    $('.last').html(quotaSelected.find('.lastQuoteElement').text());
    if (quotaSelected.find('input[name=quote]').data('gracePeriod') > 0) {
        let newAmount = Number.parseFloat(quotaSelected.find('input[name=quote]').data('grace-amount'));
        let newText = replaceNumberInString($('.graceAmount').text(), newAmount.toFixed());
        $('.gracePeriod').html(quotaSelected.find('input[name=quote]').data('grace-period'));
        $('.graceAmount').html(newText);
        $('.grace_block').removeClass('d-none');
    } else {
        $('.grace_block').addClass('d-none');
    }

    // Set TAE & TIN quota information
    var tae = parseFloat(quotaSelected.find('input[name=quote]').data('tae-value'));
    var tin = parseFloat(quotaSelected.find('input[name=quote]').data('tin-value'));
    $('.tae').html(((tae * 100).toFixed(2)).replace('.', ',') + ' %');
    $('.tin').html(((tin * 100).toFixed(2)).replace('.', ',') + ' %');
}

/**
 * Update the simulator given a list of added quotas
 * @param {Array<quota>} addedQuotas list of added quotas
 * @param {number} maxQuotas max number of quotas
 */
function updateNewQuotasInSimulator(addedQuotas, maxQuotas) {
    var $content = [];
    var monthsText = $($('.financing-quotas-labl .financing-quotas-content .quota-month')[0]).text().trim();
    var priceMonthText = $('.ca_product_detail_btn_actions .prices .sales .value span').text().trim();
    var recommendedText = $($('.financing-recommended.recommended')[0]).text().trim();
    var quoteResourceText = $($('.quoteResourceText')[0]).text().trim();

    // remove current elements
    $('.financing-box').remove();

    addedQuotas.forEach(function (quota, index) {
        var quotaContainer = document.createElement('label');
        $(quotaContainer).addClass('financing-quotas-labl');
        // input radio with data info
        var quotaInput = document.createElement('input');
        $(quotaInput).attr('type', 'radio');
        $(quotaInput).attr('name', 'quote');
        $(quotaInput).data('quotas', quota.quotas);
        $(quotaInput).data('quota-amount', quota.quotaAmount);
        $(quotaInput).data('last-quota-amount', quota.lastQuotaAmount);
        $(quotaInput).data('total-amount', quota.totalAmount);
        $(quotaInput).data('totallistpriceFormatted', $($('.finalPvpPrice')[0]).text().trim());
        $(quotaInput).data('tae-value', 0);
        $(quotaInput).data('tin-value', 0);
        $(quotaContainer).append(quotaInput);

        // quotas container with quota display info
        let quotasContent = document.createElement('div');
        $(quotasContent).addClass('financing-quotas-content');
        let quotasInMonths = document.createElement('div');
        $(quotasInMonths).addClass('quota-month');
        $(quotasInMonths).text(replaceNumberInString(monthsText, quota.quotas));
        $(quotasContent).append(quotasInMonths);
        // create price container
        let quotaPriceMonth = document.createElement('div');
        $(quotaPriceMonth).addClass('quota-price-month');
        $(quotaPriceMonth).data('origin-quote', quota.quotas);
        let quoteElement = document.createElement('span');
        $(quoteElement).addClass('quoteElement');
        $(quoteElement).text(replaceNumberInString(priceMonthText, quota.quotaAmount.toFixed(2).replace('.', ',')));
        $(quotaPriceMonth).append(quoteElement);
        let quoteResource = document.createElement('span');
        $(quoteResource).addClass('quoteResourceText');
        $(quoteResource).text(quoteResourceText);
        $(quotaPriceMonth).append(quoteResource);
        let lastQuoteElement = document.createElement('span');
        $(lastQuoteElement).addClass('lastQuoteElement d-none');
        $(lastQuoteElement).text(replaceNumberInString(priceMonthText, quota.lastQuotaAmount.toFixed(2).replace('.', ',')));
        $(quotaPriceMonth).append(lastQuoteElement);
        let totalAmountElement = document.createElement('span');
        $(totalAmountElement).addClass('totalAmountElement d-none');
        $(totalAmountElement).text(replaceNumberInString(priceMonthText, quota.totalAmount.toFixed(2).replace('.', ',')));
        $(quotaPriceMonth).append(totalAmountElement);
        let recommended = document.createElement('div');
        if (index === 0) {
            $(recommended).addClass('financing-recommended recommended');
        } else {
            $(recommended).addClass('financing-recommended');
        }
        $(recommended).html('<span>' + recommendedText + '<span>');
        $(quotasContent).append(quotaPriceMonth);
        $(quotasContent).append(recommended);
        $(quotaContainer).append(quotasContent);

        // create box
        let financingContent = document.createElement('div');
        $(financingContent).addClass('financing-content');
        $(financingContent).append(quotaContainer);
        let financingBox = document.createElement('div');
        $(financingBox).addClass('financing-box col-4');
        $(financingBox).append(financingContent);

        // call update action
        $(quotaInput).on('click', function () {
            updateSimulationSummary($(financingBox));
        });

        if (quota.quotas === maxQuotas) {
            $(quotaInput).trigger('click');
        }

        // save created element
        $content.unshift(financingBox);
    });

    $content.forEach(function (elem) {
        $('.boxes-wrapper').prepend(elem);
    });
}

/**
 * Given a list of quotas, search for the one with largest amount of months
 * @param {Array<quota>} addedQuotas list of added quotas
 * @returns {quota} Longest quota from given list
 */
function calcLongestQuota(addedQuotas) {
    var longestQuota = addedQuotas[0];
    for (let i = 1; i < addedQuotas.length; i += 1) {
        if (longestQuota.quotas < addedQuotas[i].quotas) {
            longestQuota = addedQuotas[i];
        }
    }
    return longestQuota;
}

/**
 * Updates the given complement with the matching longest quota. If there is no match, displays longest complement quota.
 * @param {DOMElement} $elem DOM element where the complement is displayed
 * @param {Array<quota>} quotas list of quotas for given complement
 * @param {quota} longestQuota complement longest quota
 * @param {string} complementPriceClass class path to acces the complement price
 * @param {string} complementQuotasClass class path to access complement quota
 */
function updateComplementOnDom($elem, quotas, longestQuota, complementPriceClass, complementQuotasClass) {
    var prodLongestQuota = quotas[0];
    var found = false;
    quotas.forEach(function (quota) {
        if (longestQuota === quota.quotas) {
            found = true;
            var newText = '';
            newText = replaceNumberInString($(complementPriceClass, $elem).text(), quota.quotaAmount.toFixed(2).replace('.', ','));
            $(complementPriceClass, $elem).text(newText.trim());
            newText = replaceNumberInString($(complementQuotasClass, $elem).text(), quota.quotas);
            $(complementQuotasClass, $elem).text(newText.trim());
        } else if (prodLongestQuota.quotas < quota.quotas) {
            prodLongestQuota = quota;
        }
    });
    if (!found) {
        var newText = '';
        if (prodLongestQuota) {
            newText = replaceNumberInString($(complementPriceClass, $elem).text(),
                prodLongestQuota.quotaAmount.toFixed(2).replace('.', ','));
            $(complementPriceClass, $elem).text(newText.trim());
            newText = replaceNumberInString($(complementQuotasClass, $elem).text(), prodLongestQuota.quotas);
            $(complementQuotasClass, $elem).text(newText.trim());
        }
    }
}

/**
 * Given the longest quota, updates complement single informations
 * @param {Array<product>} productQuotas list of quotas of each product
 * @param {quota} longestQuota longest quota
 */
function updateComplementPricesAndQuotas(productQuotas, longestQuota) {
    if (productQuotas !== null && productQuotas.length > 0) {
        var complementClass = '.ca_product_detail_complement_select';
        var complementPriceClass = '.price .sales .value span';
        let complementQuotasClass = '.fees_amount .ca_product_detail_name--mode';
        $(complementClass).each(function () {
            var $elem = $(this);
            productQuotas.forEach(function (product) {
                if ($elem.data('pid') === product.productId) {
                    updateComplementOnDom($elem,
                        product.quotas,
                        longestQuota,
                        complementPriceClass,
                        complementQuotasClass);
                }
            });
        });
    }
}

/**
 * Given a quota, updates title and button prices and quotas
 * @param {Array<quota>} longestQuota quota
 */
function updateScreenPricesAndQuotas(longestQuota) {
    // let mainPriceClass = '.ca_product_detail_info--price ';
    let addToCartPriceClass = '.ca_product_detail_btn_actions--price ';
    // update main price
    let priceClassPath = '.price .sales .value span';
    // $(mainPriceClass + priceClassPath).text(longestQuota.quotaAmount.toFixed(2).replace('.', ',') + ' €');
    // $(mainPriceClass + priceClassPath).data('quote', longestQuota.quotaAmount.toFixed(2));
    // $(mainPriceClass + priceClassPath).data('lastquote', longestQuota.lastQuotaAmount.toFixed(2));
    $(addToCartPriceClass + priceClassPath).text(longestQuota.quotaAmount.toFixed(2).replace('.', ',') + ' €');
    $(addToCartPriceClass + priceClassPath).data('quote', longestQuota.quotaAmount.toFixed(2));
    $(addToCartPriceClass + priceClassPath).data('last-quote', longestQuota.lastQuotaAmount.toFixed(2));

    // update fees amount
    let quotasClassPath = '.fees_amount .ca_product_detail_name--mode';
    var newFeesText = replaceNumberInString($(addToCartPriceClass + quotasClassPath).text(), longestQuota.quotas);
    // $(mainPriceClass + quotasClassPath).text(newFeesText.trim());
    $(addToCartPriceClass + quotasClassPath).text(newFeesText.trim());

    // update total amount in main price
    var totalAmountClassPath = '.fees_total_amount .ca_product_detail_name--mode .product-pvp .finalPvpPrice';
    var newTotalAmountText = replaceNumberInString($(addToCartPriceClass + totalAmountClassPath).text(),
        longestQuota.totalAmount.toFixed(2).replace('.', ','));
    // $(mainPriceClass + totalAmountClassPath).text(newTotalAmountText);
    $(addToCartPriceClass + totalAmountClassPath).text(newTotalAmountText);

    // update add-to-cart button price
    $('.ca_product_detail_btn_actions--price .price .sales .value span').text(longestQuota.quotaAmount.toFixed(2).replace('.', ',') + ' €');
}

/**
 * Updates PDP floating box with prices for the set and assurance
 * @param {json} complementTotalPrices Json containing assurance and accesories prices
 */
function updatePriceBox(complementTotalPrices) {
    let discountedTotalClass = '.discountedTotal';
    let setInfoClass = '.setInfoPrice';
    let labelClass = '.complements-label';
    let valueClass = '.complements-value';
    var $assurancePriceElem = $('span.noInsurancePrice');
    var mainProdPrice = parseFloat(($('.mainProdPrice').first().text()).replace('€', '').replace('.', '').replace(',', '.'));
    // Discounted
    if ($('.ca_product_detail--discount').last()) {
        if ($('.initialDiscountedPrice').val()) {
            var lastPrice = parseFloat(($('.initialDiscountedPrice').val()).replace('€', '').replace('.', '').replace(',', '.'));
        }
    }

    if (complementTotalPrices.prodsPrice) {
        var complementsTotalPrice = parseFloat((complementTotalPrices.prodsPrice).replace('.', '').replace(',', '.'));
    }

    if (complementTotalPrices.assurance) {
        var assurancePrice = parseFloat((complementTotalPrices.assurance).replace('.', '').replace(',', '.'));
    }

    var newTotal = 0;
    var newLast = 0;
    if (complementsTotalPrice && assurancePrice) {
        $(setInfoClass).removeClass('d-none');
        $(discountedTotalClass).addClass('d-none');
        $(valueClass).text(complementTotalPrices.prodsPrice);
        $(labelClass).removeClass('d-none');
        $assurancePriceElem.text(complementTotalPrices.assurance);
        $assurancePriceElem.last().removeClass('d-none');

        newTotal = assurancePrice + complementsTotalPrice + mainProdPrice;
        newLast = lastPrice + assurancePrice + complementsTotalPrice;
    } else if (!complementsTotalPrice && assurancePrice) {
        $(setInfoClass).removeClass('d-none');
        $(discountedTotalClass).addClass('d-none');
        $(labelClass).addClass('d-none');
        $assurancePriceElem.text(complementTotalPrices.assurance);
        $assurancePriceElem.last().removeClass('d-none');

        newTotal = assurancePrice + mainProdPrice;
        newLast = lastPrice + assurancePrice;
    } else if (complementsTotalPrice && !assurancePrice) {
        $(setInfoClass).removeClass('d-none');
        $(discountedTotalClass).addClass('d-none');
        $(valueClass).text(complementTotalPrices.prodsPrice);
        $(labelClass).removeClass('d-none');
        $assurancePriceElem.last().addClass('d-none');

        newTotal = complementsTotalPrice + mainProdPrice;
        newLast = lastPrice + complementsTotalPrice;
    } else {
        $(setInfoClass).addClass('d-none');
        $(discountedTotalClass).removeClass('d-none');
        $(labelClass).addClass('d-none');
        $assurancePriceElem.last().addClass('d-none');

        $('.setTotalValue').text($('.mainProdPrice').first().text());
        if ($('.ca_product_detail--discount').last()) {
            var initialDiscountedPrice = $('.initialDiscountedPrice').val();
            $('.ca_product_detail--discount').last().text(initialDiscountedPrice);
        }
    }
    if (newTotal > 0) {
        // Formatted following the german locale, because for the spanish one,
        // numbers with less than 4 digits does not follow the required "style"
        newTotal = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(newTotal);
        $('.setTotalValue').text(newTotal);
    }
    if (newLast > 0) {
        // Formatted following the german locale, because for the spanish one,
        // numbers with less than 4 digits does not follow the required "style"
        newLast = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(newLast);
        $('.ca_product_detail--discount').last().text(newLast);
    }
}

module.exports = {
    selectProductSetComplement: function () {
        $('body').on('click', '.ca_product_detail_complement_select, input[name="radio-seguro"]', function () {
            var $currentElem = $(this);
            $(this).toggleClass('active');
            var selectedProds = '';
            // loop over insurances
            if ($(this).hasClass('ca_product_detail_complement_select')) {
                $('.ca_secure_pdp').each(function () {
                    if ($('.radio-seguro:checked', this).data('insurance-selected') && $(this).data('pid') !== undefined) {
                        selectedProds += $(this).data('pid') + ',';
                    }
                });
            } else if ($(this).data('pid') !== undefined) {
                selectedProds += $(this).data('pid') + ',';
            }

            // loop over product complements
            $('.product-complement.active').each(function () {
                selectedProds += $(this).data('pid') + ',';
            });

            $.ajax({
                url: $(this).data('href'),
                data: {
                    selectedProduct: selectedProds.slice(0, -1),
                    mainProd: $('.product-detail.product-set-detail').data('pid'),
                    pid: $(this).data('set-prod'),
                    pview: 'detail'
                },
                method: 'GET',
                success: function (response) {
                    var longestQuota = calcLongestQuota(response.addedQuotas);
                    updateScreenPricesAndQuotas(longestQuota);
                    updateComplementPricesAndQuotas(response.productQuotas, longestQuota.quotas);
                    updateNewQuotasInSimulator(response.addedQuotas, longestQuota.quotas);
                    updatePriceBox(response.complementsTotalPrices);
                },
                error: function () {
                    $currentElem.toggleClass('active');
                }
            });
        });
    },
    selectInsuranceComplement: function () {
        $('body').on('click', 'input[name="radio-seguro"]', function () {
            var insuranceSelected = $('.ca_secure_pdp').find('[data-insurance-selected=true]');
            if (insuranceSelected.length) {
                // eslint-disable-next-line max-len
                insuranceSelected.attr('data-insurance-selected', false);
            }
            $(this).attr('data-insurance-selected', true);
            var selectPopUpCoverage = $('.ca_coverage');
            if ($(this).val() !== '0') {
                // Manage coverage modal
                var monthsWarranty = $(this).attr('data-monthswarranty');
                var pvpInsurance = $(this).val();
                $('.a_coverage').removeClass('d-none');
                selectPopUpCoverage.find('.ca_coverage_secun.p_MonthsWarranty .ca_span_modal_1').text(monthsWarranty);
                selectPopUpCoverage.find('.ca_coverage_secun_sub.p_Price .ca_span_modal_2').text(pvpInsurance.replace('.', ','));

                // Update insurance PVP info
                $('.ca_product_detail_btn_actions--price').find('.product-pvp span.noInsurance').removeClass('d-none');
                // $('.ca_product_detail_btn_actions--price').find('.product-pvp span.noInsurancePrice').text(
                //    $(this).val().replace('.', ',') + ' €'
                // );
                $('.product-pvp span.noInsurance').appendTo('.setInfoPrice');
            } else {
                // Manage coverage modal
                $('.a_coverage').addClass('d-none');

                // Update insurance PVP info
                $('.ca_product_detail_btn_actions--price').find('.product-pvp span.noInsurance').addClass('d-none');
            }
        });
    },
    variationOptions: function () {
        var isVariationOptions = $('.variation-options');
        if (isVariationOptions.length) {
            var variationSelected = $('input[name="variationAtt"]:checked');
            var btnSimulator = $('.ca_pdp_simulator');
            if (!variationSelected.length && btnSimulator.length) {
                $('.ca_pdp_simulator').prop('disabled', true);
            }
        }
    },
    selectSimulationQuota: function () {
        if ($('input[type=radio][name=quote]:checked').length) {
            updateSimulationSummary($('input[type=radio][name=quote]:checked').closest('.financing-box'));
        }
        $('input[type=radio][name=quote]').on('click', function () {
            updateSimulationSummary($(this).closest('.financing-box'));
        });
    },
  
    // set bottom margin 1rem between paragraphs and 1rem last paragraph
    setMarginBottomDescription: function() {
        const rem1 = (24 / 13) + 'rem';
        const rem2 = (16 / 13) + 'rem';
        const element = $('#collapseDescripcion .card-body p:not(:has(b, a, i))');
        element.css('margin-bottom', rem1);
        element.prev('p').css('margin-bottom', rem2);
    }
};
