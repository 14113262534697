'use strict';

/**
 * Hide carousel next button when there are not enough elements to scroll
 * @param {string} carouselId the carousel ID to check
 */
var enableDisableCarouselButtons = function (carouselId) {
    var itemsPerSlide = parseInt($(carouselId).data('maximum-items-per-slide'), 10);
    var totalItems = $(carouselId + ' .carousel-item').length;

    if (itemsPerSlide >= totalItems) {
        $(carouselId + ' .carousel-control-next').addClass('d-none');
    }
};

/**
 * Defines limits and function for multiple slide carousels
 * @param {string} carouselId the id of the carousel element
 */
var configureMultiSlideCarousel = function (carouselId) {
    $(carouselId).on('slide.bs.carousel', function (e) {
        var itemsPerSlide = parseInt($(this).data('maximum-items-per-slide'), 10);
        var totalItems = $('.carousel-item', this).length;

        if ((e.to + itemsPerSlide) >= totalItems) {
            $('.carousel-control-next', this).addClass('d-none');
        } else {
            $('.carousel-control-next', this).removeClass('d-none');
        }
        if (e.to === 0) {
            $('.carousel-control-prev', this).addClass('d-none');
        } else {
            $('.carousel-control-prev', this).removeClass('d-none');
        }
    });
};

var visibilityPriceProducts = function () {
    $('.price .value').each(function () {
        var priceValue = $(this).attr('content');
        if (priceValue === 'null') {
            $(this).parents('.price').siblings('.noPrice').removeClass('d-none');
            $(this).parents('.price').addClass('d-none');
        } else {
            $(this).parents('.price').siblings('.noPrice').addClass('d-none');
            $(this).parents('.price').removeClass('d-none');
        }
    });
};

var setProductTags = function () {
    var tags = $('body').find('[data-js="product-tag"]');
    var font;
    for (var i = 0; i < tags.length; i += 1) {
        tags[i].style.setProperty('--tag-background', tags[i].getAttribute('data-background'));
        font = tags[i].getAttribute('data-font').split(' ');
        tags[i].style.setProperty('--tag-font-weight', font[0]);
        tags[i].style.setProperty('--tag-font-size', font[1]);
        tags[i].style.setProperty('--tag-font-color', font[2]);
        if (tags[i].getAttribute('data-border').length !== 0) {
            tags[i].style.setProperty('--tag-border', 'solid ' + tags[i].getAttribute('data-border'));
        }
    }
};

$(function () {
    enableDisableCarouselButtons('#opportunities-carousel');
    enableDisableCarouselButtons('#recommendations-carousel');

    configureMultiSlideCarousel('#opportunities-carousel');
    configureMultiSlideCarousel('#recommendations-carousel');
    visibilityPriceProducts();
    setProductTags();
});

module.exports = {
    setProductTags: setProductTags
};
