'use strict';

/**
 *  Fetches a PDF via AJAX XMLHttpRequest call and displays it in a modal.
 *  @param {string} filePath - Path of the file to view
 *  @param {string} webDAVAuthorization - The WebDAV Authorization to acces to the file
 */
function insertPDFInModal(filePath, webDAVAuthorization) {
    var defer = $.Deferred();

    $.ajax({
        url: filePath,
        type: 'get',
        headers: {
            Authorization: 'Basic ' + webDAVAuthorization
        },
        contentType: 'application/pdf',
        xhrFields: {
            responseType: 'blob'
        },
        success: function (data) {
            if (data) {
                $('#pdfObjectViewer').attr('data', window.URL.createObjectURL(data));
                defer.reject(data);
                $.spinner().stop();
                $('#documentModal').modal().show();
            } else {
                defer.resolve(data);
            }
        },
        error: function (request, status, err) {
            if (err) {
                console.log(request.responseText);
                console.log(request.status);
                console.log(status);
            }
            defer.reject(err);
            $.spinner().stop();
        }
    });
}

module.exports = {
    showDocument: function () {
        $(document).on('click', '[data-js="showDocument"]', function (e) {
            e.preventDefault();
            var $this = $(this);
            var action = $this.attr('data-action');
            if ($('#documentModal').length > 0) {
                $('#documentModal').detach();
            }

            $.ajax({
                url: action,
                method: 'GET',
                beforeSend: function () {
                    $.spinner().start();
                },
                success: function (data) {
                    if (!data.errorHtml) {
                        $('#maincontent').append($(data.documentModalHtml));
                        if (!data.error) {
                            if ($this.text().length > 0) {
                                $('#documentModal').find('.modal-title').text($this.text());
                            }
                            insertPDFInModal(data.filePath, data.webDAVAuthorization);
                        } else {
                            $('#documentModal').modal().show();
                            console.log(data.errorMessage);
                            $.spinner().stop();
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    }
};
