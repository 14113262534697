'use strict';

// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');
window.moment = require('moment');
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('base/components/menu'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/document'));

    processInclude(require('./page'));
    processInclude(require('./components/home'));
    processInclude(require('./login/customerLogin'));
    processInclude(require('base/components/menu'));
    processInclude(require('./components/siteModal'));
    processInclude(require('../../../../../int_ccf_omnichannel/cartridge/client/default/js/prefixCombobox/prefixCombobox'));
    require('base/components/spinner');
});

require('base/thirdParty/bootstrap');
require('./thirdParty/bootstrap-slider');
require('base/components/spinner');
require('./thirdParty/moment');
require('./thirdParty/date-range-picker');
require('./thirdParty/daterangepicker');
