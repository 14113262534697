'use strict';

var enableConfirmButton = function () {
    if ($('#IMAGIN_ES_PNBL').hasClass('active')
        || $('#IMAGIN_ES_CN').hasClass('active')
        || $('#IMAGIN_ES_CEML').hasClass('active')) {
        if ($('#EMP').hasClass('active')) {
            $('#EMP').removeClass('active');
            $('.btn-confirm').prop('disabled', true);
        }
        else {
            $('.btn-confirm').prop('disabled', false);
        }
        $('#EMP').addClass('d-none');
        $('#IMAGIN_ES_PNBL').removeClass('d-none');
        $('#IMAGIN_ES_CN').removeClass('d-none');
        $('#IMAGIN_ES_CEML').removeClass('d-none');
    }
    // eslint-disable-next-line max-len
    else if ($('#EMP').hasClass('active') && ($('#ca-change_geographical_location').val() !== '' && $('#ca-change_customer_type').val() !== '')) {
        $('.btn-confirm').prop('disabled', false);
        $('#EMP').removeClass('d-none');
        $('#IMAGIN_ES_PNBL').addClass('d-none');
        $('#IMAGIN_ES_CN').addClass('d-none');
        $('#IMAGIN_ES_CEML').addClass('d-none');
    }
    else if ($('#ca-change_geographical_location').val() !== '' && $('#ca-change_customer_type').val() !== '') {
        $('.btn-confirm').prop('disabled', false);
        $('#EMP').removeClass('d-none');
        $('#IMAGIN_ES_PNBL').removeClass('d-none');
        $('#IMAGIN_ES_CN').removeClass('d-none');
        $('#IMAGIN_ES_CEML').removeClass('d-none');
    }
};

module.exports = {
    initSiteModal: function () {
        $('.ca-geolocation .btn').on('click', function () {
            $('.ca-geolocation').children('.btn').removeClass('active');
            $(this).addClass('active');
            $('#geoLocation').val($(this).attr('value'));
            enableConfirmButton();
        });
        $('.ca-profile .btn').on('click', function () {
            $('.ca-profile').children('.btn').removeClass('active');
            $(this).addClass('active');
            $('#customerType').val($(this).attr('value'));
            enableConfirmButton();
        });

        $('#formChangeSite').on('submit', function (e) {
            e.preventDefault();

            var action = $('.page').attr('data-action');
            if (action === '' || action === null) {
                action = 'Home-Show';
            }
            var queryString = $('.page').attr('data-querystring');

            $.ajax({
                url: $('#formChangeSite').attr('action'),
                type: 'post',
                dataType: 'json',
                data: {
                    customerType: $('.ca-profile .btn.active').attr('value'),
                    geoLocation: $('.ca-geolocation .btn.active').attr('value'),
                    action: action,
                    queryString: queryString
                },
                success: function (response) {
                    $.spinner().stop();
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    }
};
